import React, { FC, ReactNode } from 'react'

interface ComponentProps {
  children: ReactNode
  onClick?: () => void
  className?: string
}

const Component: FC<ComponentProps> = ({ children, onClick, ...props }) => {
  const Tag = onClick ? 'button' : 'div'
  return (
    <Tag onClick={onClick} {...props}>
      {children}
    </Tag>
  )
}

interface SelectionProps {
  active?: boolean
  children: ReactNode
  onClick?: () => void
  className?: string
  small?: boolean
  dark?: boolean
}

const Selection: FC<SelectionProps> = ({ active, children, onClick, className, small, dark }) => {
  const selectionClasses = `
    ${className ?? ''} 
    ${small ? 'px-2 py-1 text-sm' : 'px-4 py-2 text-base'}
    ${dark ? 'bg-brand-dark text-white' : 'bg-white text-black'} 
    ${active ? 'bg-brand-contrast font-bold' : ''} 
    ${onClick ? 'cursor-pointer' : ''} 
    flex items-center border-0 outline-none rounded-full shadow-sm transition-all text-black inline-flex
    hover:brightness-75
  `

  return (
    <Component onClick={onClick} className={selectionClasses}>
      {children}
    </Component>
  )
}

export default Selection
