import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react'
import { useScrollDirection } from './useScrollDirection'

interface NavigationContextType {
  isBarHidden: boolean
}

const NavigationContext = createContext<NavigationContextType | undefined>(undefined)

export const useNavigation = () => {
  const context = useContext(NavigationContext)
  if (!context) {
    return { isBarHidden: false }
  }
  return context
}

interface NavigationProviderProps {
  children: ReactNode
}

export const NavigationProvider: React.FC<NavigationProviderProps> = ({ children }) => {
  const [isBarHidden, setIsBarHidden] = useState(false)

  const { scrollDirection } = useScrollDirection()

  useEffect(() => {
    if (scrollDirection === 'down') {
      setIsBarHidden(true)
    }
    if (scrollDirection === 'up') {
      setIsBarHidden(false)
    }
  }, [scrollDirection])

  return <NavigationContext.Provider value={{ isBarHidden }}>{children}</NavigationContext.Provider>
}
