import './src/styles/global.css'
import 'typeface-poppins'
import { config } from '@fortawesome/fontawesome-svg-core'
import { GatsbyBrowser } from 'gatsby'
import React from 'react'
import '@fortawesome/fontawesome-svg-core/styles.css'
import ClientOnly from './src/components/ClientOnly'
import CookieConsent from './src/components/CookieConsent'
import PageWrapper from './src/components/PageWrapper'
import { NavigationProvider } from './src/context/NavigationContext'

config.autoAddCss = false

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <NavigationProvider>
    {element}
    <ClientOnly>
      <CookieConsent />
    </ClientOnly>
  </NavigationProvider>
)

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => <PageWrapper>{element}</PageWrapper>

export const shouldUpdateScroll = ({ routerProps: { location } }) => !location.state?.noScroll
