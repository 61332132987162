import { useState, useEffect, useRef } from 'react'

type ScrollDirection = 'initial' | 'up' | 'down' | null

export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>('initial')
  const lastScrollTop = useRef(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY

      if (currentScrollTop <= 40) {
        // display the nav always at the top
        setScrollDirection('up')
      } else if (currentScrollTop > lastScrollTop.current + 10) {
        setScrollDirection('down')
      } else if (currentScrollTop < lastScrollTop.current - 10) {
        setScrollDirection('up')
      }

      lastScrollTop.current = currentScrollTop
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return { scrollDirection }
}
