import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { ParallaxProvider } from 'react-scroll-parallax'

type Props = {
  children?: React.ReactNode
}

const PageWrapper: React.FC<Props> = ({ children }) => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
  }, [])

  return <ParallaxProvider>{children}</ParallaxProvider>
}

export default PageWrapper
