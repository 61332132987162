import React from 'react'

interface ContainerProps {
  children?: React.ReactNode
  className?: string
  noPadding?: boolean
}

const Container: React.FC<ContainerProps> = ({ children, className, noPadding }) => {
  const externalClassnames = className ? className.split(' ') : []
  const containsPadding = externalClassnames.some((classname) => classname.startsWith('px-'))

  return (
    <div className={`container mx-auto ${!noPadding && !containsPadding ? 'px-5' : ''} ${className ?? ''}`}>
      {children}
    </div>
  )
}

export default Container
