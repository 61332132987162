import { graphql, useStaticQuery } from 'gatsby'
import Cookies from 'js-cookie'
import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { CookieConsentQuery } from '../../generated/graphql-types'
import Container from '../Container'
import Selection from '../Selection'

const initGA = () => {
  const id = process.env.GATSBY_GOOGLE_ANALYTICS_ID
  if (id) {
    ReactGA.initialize(id)
  }
}

const CookieConsent: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true)
  const [shouldRender, setShouldRender] = useState(true)

  const cookieConsets = useStaticQuery<CookieConsentQuery>(graphql`
    query CookieConsent {
      allContentfulCookieConsent {
        edges {
          node {
            description
            node_locale
            title
          }
        }
      }
    }
  `)

  useEffect(() => {
    const hasConsent = Cookies.get('user-consent')
    if (hasConsent) {
      setIsVisible(false)
      setShouldRender(false)
    }
    if (Cookies.get('analytics-cookies') === 'enabled') {
      initGA()
    }
  }, [])

  const cookieConsent = cookieConsets.allContentfulCookieConsent.edges.find(({ node }) => node.node_locale === 'fi')

  if (!cookieConsent || !shouldRender) {
    return null
  }

  const acceptAllCookies = () => {
    Cookies.set('user-consent', 'all', { expires: 365 })
    Cookies.set('analytics-cookies', 'enabled', { expires: 365 })
    initGA()
    setIsVisible(false)
    setTimeout(() => {
      setShouldRender(false)
    }, 500)
  }

  const acceptNecessaryCookies = () => {
    Cookies.set('user-consent', 'necessary', { expires: 365 })
    Cookies.remove('analytics-cookies')
    setIsVisible(false)
    setTimeout(() => {
      setShouldRender(false)
    }, 500)
  }

  const { title, description } = cookieConsent.node
  return (
    <div
      className={`fixed inset-x-0 bottom-0 p-4 bg-gray-800 border-t-[1px] border-stone-800 shadow-lg text-white transition-all duration-500 ease-in-out ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[100%]'
      } z-10`}
    >
      <Container noPadding>
        <div className="w-full flex flex-col lg:flex-row">
          <div className="w-full lg:w-8/12">
            <p className="font-bold">{title}</p>
            <p className="text-xs lg:text-base">{description}</p>
          </div>

          <div className="w-full lg:w-6/12 flex flex-wrap justify-end items-center pb-0 lg:pb-5 pt-5">
            <Selection dark className="text-xs lg:text-base my-1 ml-3 px-4 h-10 whitespace-nowrap" small>
              <button onClick={acceptNecessaryCookies}>Vain välttämättömät</button>
            </Selection>
            <Selection dark className="text-xs lg:text-base my-1 ml-3 px-4 h-10 whitespace-nowrap" small>
              <button onClick={acceptAllCookies}>Hyväksy kaikki</button>
            </Selection>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default CookieConsent
